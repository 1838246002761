<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@suaw/suaw-component-library";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "ShutUpAndWritesNanowrimoSurvivalGuide",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="ShutUpAndWritesNanowrimoSurvivalGuide">
      <SuawParagraph
        text="November 1st, the first day of NaNoWriMo, is the start of a 30-day journey toward 50,000 words. It’s going to be intense and exhilarating and emotional. It’s not just about the commitment, the anticipation of success or fear of failure—it’s about digging deep into the unknown and pushing past mental and physical barriers to create a novel: your novel."
      />
      <SuawParagraph text="You’ve got this." />
      <SuawParagraph
        text="You're prepared. You know what you’re writing and when you’re writing. You’ve got your writing buddy standing at your side. You’ve got Shut Up &amp; Write! in your corner."
      />
      <SuawParagraph text="(Even if that’s only mostly true, we believe in you!)" />
      <SuawParagraph text="This Survival Guide is your resource throughout the month of November." />
      <SuawParagraph
        text="Read it now to get yourself ready for the next 30 days, but if you find yourself stuck or frustrated, come back to it! Also, be sure to attend Shut Up &amp; Write! events online or in your area to connect with other writers. As we always say, writing does not have to be an isolating activity."
      />
      <SuawHeading level="3" content="Pro Tips" />
      <SuawParagraph
        text="<ul><li>Write whenever you can (in addition to your scheduled writing time). Anytime you find yourself with spare time, use it to write or think about your novel. Work through that troublesome plot question or that dialogue that still doesn’t quite work. Take notes in your notebook or phone. The minutes will add up.</li><li>Use a timer to increase your focus (and word count) by writing for short bursts. You can write for 20 minutes, 30 minutes, or even an hour at a time and take breaks in between. If you’d like to go one step further than a simple timer, many free time tracking and accountability apps are available for your computer, phone, and tablet.</li><li>Headphones are your friend. It can be helpful to play music, white noise or even <a target='_blank' title='https://coffitivity.com' href='https://coffitivity.com'>coffee shop sounds</a> if you’re writing at home. This can help you get into the writing mindset. Some people swear by wearing noise-canceling headphones or earbuds while writing to block out the rest of the world completely.</li><li>Beware the middle of the month. This is when the initial enthusiasm can fade, replaced by the feeling that you’re slogging your way through the story. It’s not unusual to start hating your characters and your story, and wondering why you started this in the first place. Often, coming to in-person writing events can give you the support you need during this time.</li></ul>"
      />
      <SuawHeading level="3" content="Best Practices While Writing" />
      <SuawParagraph
        text='<ul><li>Don’t check your word count. Just write! Writing for a set amount of time is easier because counting words too often can squash your creativity. Check only after your first hour.</li><li>Take breaks, but not too often. Once an hour, take a ten or fifteen-minute break to refresh your tea or coffee and go to the bathroom.</li><li><strong>Turn off the internet and your phone.</strong> Treat your writing time like a performance — you wouldn’t let your phone interrupt an enactment of "The Merry Wives of Windsor," so don’t let the world interrupt your writing either.</li><li>End every session with a note of what you’re going to work on next, so your brain has time to mull it over between writing sessions. Make sure this note is about a character, scene, or topic that you’re excited to get back to.</li><li>The weekend is your friend. Set aside as many large blocks of time as you can. You may find yourself having to do some catch-up, so build it into your schedule.</li><li>Don’t edit. We know it’s tempting, but your goal is to lay down those words as fast as you can. You really can’t start revising and editing until you have a complete draft, otherwise you could spend the entire month attempting to rewrite and refine only 10 pages.</li><li>Don’t give up. One of our hosts once went into the last week of NaNoWriMo 30,000 words short; she almost quit but decided to make a last-ditch effort and finished those last 30,000 words with two days to spare!</li></ul>'
      />
      <SuawHeading level="3" content="Need some help?" />
      <SuawParagraph text='<strong>"I can’t keep to my writing schedule!"</strong>' />
      <SuawParagraph
        text="If you have a family, travel often, or just have a really busy schedule, then finding large blocks of time to write can be tough. While it’s helpful to finish a day’s writing all at once, it isn’t necessary. You have more time than you think."
      />
      <SuawParagraph
        text="<ul><li>Try getting up a little earlier, or staying up a little later.</li><li>Write during your lunch break.</li><li>Give up all TV for the month.</li><li>Tell your family and friends how important this is to you and ask for their help and support.</li><li>Promise your partner you’ll do all the dishes and cooking for the month of December.</li></ul>"
      />
      <SuawParagraph
        text="Remember, emergencies and unexpected things will come up. Don’t get bogged down with guilt or remorse. Re-evaluate your schedule and commitments. Remember, you can do this."
      />
      <SuawParagraph text='<strong>"I don’t know what to write next!"</strong>' />
      <SuawParagraph
        text="Perhaps you’re not sure how a scene ends or how your hero gets out of the pickle you’ve put them in. Maybe you’re not sure who the bad guy is. Whatever writing problem you’re wrestling with, here are a few tricks for getting yourself unstuck and back to writing."
      />
      <SuawParagraph
        text="<ul><li>Take a walk or a break; let your subconscious work at it in the background.</li><li>Push through it; pick a direction and go with it. You can always come back to a tricky scene later if it doesn’t work. You may find the story carries forward better than you anticipated.</li><li>Sometimes the simplest solution is to move onto another scene and come back to it later.</li></ul>"
      />
      <SuawParagraph text='<strong>"I tried, but I can’t write at home!"</strong>' />
      <SuawParagraph
        text="It can be hard to stay focused on your writing when your family wants your attention, or your roommate is loud, or your laundry is piling up in the corner of your room."
      />
      <SuawParagraph
        text="<ul><li>Writing at a coffee shop is a classic choice.</li><li>Try staying an extra half hour after work to write.</li><li>Don’t be afraid to mix it up. Try writing in a restaurant, or a bar, or even on a park bench.</li></ul>"
      />
      <SuawParagraph text='<strong>"I’ve lost my motivation! / I don’t like my character, story, anything!"</strong>' />
      <SuawParagraph
        text="First of all, what you’re feeling is normal. NaNoWriMo is a journey where you learn as much about yourself as you do the novel you’re writing. Working through these moments is part of the process; every writer goes through it."
      />
      <SuawParagraph text="Whatever the problem, here are a few tips to get you writing again:" />
      <SuawParagraph
        text="<ul><li>Identify where you’re stuck and what isn’t working with your story.</li><li>Focus on that single thing and decide on a solution. It doesn’t matter if you’re not sure if it will work — this is only a draft. You can fix it later.</li><li>When all else fails, trust yourself and keep writing.</li></ul>"
      />
      <SuawHeading level="3" content="Inspiration" />
      <SuawParagraph text="“Start writing, no matter what. The water does not flow until the faucet is turned on.” ― Louis L’Amour" />
      <SuawParagraph text="“Don’t worry about what you’re writing or whether it’s good or even whether it makes sense.” ― Lauren Oliver" />
      <SuawParagraph
        text="“It’s not always about writing more words or drinking more coffee. Sometimes getting to the end of a novel simply takes remembering that the world is more complicated than we know, and then sticking some of those complications into the story.” ― Scott Westerfeld"
      />
      <SuawParagraph text="“You must stay drunk on writing so reality cannot destroy you.” ― Ray Bradbury" />
      <SuawParagraph text="“Writing is a socially acceptable form of schizophrenia.” — E.L. Doctorow" />
      <SuawParagraph
        text="“Don’t bend; don’t water it down; don’t try to make it logical; don’t edit your own soul according to the fashion. Rather, follow your most intense obsessions mercilessly.” ― Franz Kafka"
      />
      <SuawParagraph text="“It is perfectly okay to write garbage—as long as you edit brilliantly.” — C. J. Cherryh" />
      <SuawParagraph text="And finally, focus on your goal, keep to your schedule and get those words down!" />
    </ArticleFrame>
  </SuawMainContent>
</template>
